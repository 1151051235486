<template>
  <div></div>
</template>

<script>
import { getCurrentInstance, onBeforeUnmount, onMounted } from "vue";
import { useRouter } from "vue-router";

import userInfoApi from "@/api/userInfo";

export default {
  name: "Auth",
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();

    const unionidCheck = async (unionid) => {
      const res = await proxy.$request.handleFetchPostJson(
        userInfoApi.unionid_check,
        { union_id: unionid }
      );
      if (res.bind) {
        if (res.token) {
          proxy.$cookie.setCookie("tokenid", res.token, 25, "/");
        }
        router.replace({
          name: "home",
        });
      } else {
        router.replace({
          name: "register",
        });
      }
    };

    const getUnionid = async (code) => {
      const res = await proxy.$request.handleFetchGet(
        `${userInfoApi.authorization}?code=${code}`
      );
      proxy.$cookie.setCookie("unionid", res.unionid, 25, "/");
      unionidCheck(res.unionid);
    };

    onMounted(() => {
      let code = router.currentRoute.value.query.code,
        unionid = proxy.$cookie.getCookie("unionid"),
        tokenid = proxy.$cookie.getCookie("tokenid");
      if (unionid == "" || tokenid == "") {
        if (code !== undefined) {
          getUnionid(code);
        } else {
          router.replace({
            name: "authBefore",
          });
        }
      } else {
        unionidCheck(unionid);
      }
    });
  },
};
</script>
